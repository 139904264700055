<template>
    <!-- <Transition>
    <div v-if="showAboutContent" class="about-us-modal modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <div class="has-text-centered"><img src="/img/logo-width.png" class="logo" /></div>
                <h3 class="is-size-3 has-text-centered">Tentang Kami</h3>
                <div class="content-media-about-us">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pretium nibh ipsum consequat nisl vel pretium. Ornare massa eget egestas purus viverra accumsan in nisl. Adipiscing elit pellentesque habitant morbi tristique senectus et netus. Pharetra pharetra massa massa ultricies mi quis hendrerit. Vitae tempus quam pellentesque nec. Adipiscing tristique risus nec feugiat in fermentum posuere. Ornare massa eget egestas purus viverra. Porttitor rhoncus dolor purus non enim. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Integer quis auctor elit sed vulputate. Nisl nisi scelerisque eu ultrices vitae auctor eu.</p>

                    <p>Amet aliquam id diam maecenas ultricies mi eget mauris pharetra. Morbi leo urna molestie at elementum eu facilisis sed. Non arcu risus quis varius quam quisque id diam. Vestibulum lectus mauris ultrices eros in cursus turpis. Turpis egestas sed tempus urna. Quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna. Odio eu feugiat pretium nibh ipsum consequat nisl. Amet tellus cras adipiscing enim eu. Nisi porta lorem mollis aliquam ut porttitor leo a. Nisi est sit amet facilisis magna etiam tempor orci. Quam elementum pulvinar etiam non quam lacus suspendisse. Vitae tortor condimentum lacinia quis. Laoreet non curabitur gravida arcu ac tortor dignissim. Cursus euismod quis viverra nibh cras pulvinar mattis. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus.</p>
                </div>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click.prevent="showAboutContent = false"></button>
    </div>
    </Transition> -->
    <Transition>
    <div v-if="showInstall" class="buttons about-us-button">
        <button class="button is-warning" type="button" @click.prevent="doInstall"><img src="/img/install-icon.png"/> Install GeDoor</button>
    </div>
    </Transition>
    <div id="main-slider">
        <img src="/img/main-banner.jpg" alt="Konsultasi Gratis">
    </div>
    <div id="main-content" class="mobile-container homepage-content">

        <div class="columns is-mobile is-tablet service-list" v-for="rowIdx in Math.ceil(Object.keys(services).length / 3)" :key="rowIdx">
            <div class="column is-one-third" v-for="serviceKey in Object.keys(services).slice(3 * (rowIdx - 1), 3 * rowIdx)" :key="serviceKey">
                <div class="service-wrapper">
                    <router-link :to="'/order/' + serviceKey">
                        <span class="service-icon">
                            <img :src="'/img/icon-jasa/' + services[serviceKey].icon">
                        </span>
                        <span class="service-title">
                            <h3>{{services[serviceKey].title}}</h3>
                        </span>
                    </router-link>
                </div>
            </div>
        
        </div>

    </div>
</template>

<script>
import servicesData from "@/json/services.json"

export default {
    data () {
        return {
            showAboutContent: false,
            showInstall: false,
            deferredPrompt: null,
            services: servicesData
        }
    },
    mounted() {
        window.addEventListener('beforeinstallprompt', (e) => {
            // e.preventDefault()
            this.deferredPrompt = e
            this.showInstall = true
        })
        window.addEventListener('appinstalled', () => {
            this.showInstall = false
            this.deferredPrompt = null
        });
    },
    methods: {
        dismissPrompt() {
            this.showInstall = false
        },
        installPWA() {
            this.deferredPrompt.prompt()
            this.deferredPrompt.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the prompt once the user's clicked
                if (choice.outcome === 'accepted') {
                    this.showInstall = false
                    // Do something additional if the user chose to install
                } else {
                    // Do something additional if the user declined
                }
            })
        },
    },
    created () {
    },
    unmounted () {
        // window.removeEventListener('scroll', this.onWindowScroll)
    }
}
</script>
